import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import MainLayout from '../components/layouts/MainLayout';
import PaymentLoading from '../components/views/PaymentLoading';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import * as api from '../api/sanha';

const CheckOutPaymentResponseContainer = ({
  history,
  match,
}) => {
  const urlQueryStringParams = queryString.parse(history.location.search);
  const { bookingItem } = useSelector(state => state.bookingList);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    history.replace('/');
  };

  const registCheckOutPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.ResultCode !== '3001') {
        setModalErrorMessage('결제 요청에 실패 하였습니다.');
        throw new Error('');
      }
      setIsLoading(true);
      const { data: registCheckOutPaymentResponse } = await api.registCheckOutPayment({
        folioNo: bookingItem.folioNo,
        trnsNo: urlQueryStringParams.Tid,
        intApprTypeCode: 'P',
        intPayStatusCode: 11,
        payFnshDate: '',
        successYn: urlQueryStringParams.ResultCode === '3001' ? 'Y' : 'N',
        trnsDate: moment().format('YYYYMMDD'),
        trnsHHMMSS: moment().format('HHmmss'),
        trnsAmt: urlQueryStringParams.Amt,
        cardMonthCnt: urlQueryStringParams.CardQuota || '',
        cardApprNo: urlQueryStringParams.AuthCode || '',
        cardName: urlQueryStringParams.AppCardName || '',
        cardIssueCode: urlQueryStringParams.AppCardCode || '',
        cardPurcCode: urlQueryStringParams.AcquCardCode || '',
        trnsBankCode: '',
        acctNo: '',
        depositor: urlQueryStringParams.BuyerName || '',
        cashRcptTypeCode: 0,
        cashRcptApptNo: '',
        cashRcptCxlApptNo: '',
        responseCode: urlQueryStringParams.ResultCode || '',
        message1: urlQueryStringParams.ResultMsg || '',
        message2: '',
        dpsitPayDivCode: '01',
        userId: 'KEYLESS',
        userIp: '127.0.0.0',
        payMgtNo: '',
        dpsitPayNo: '',
        outYN: '',
        outMsg: '',
      });
      if (registCheckOutPaymentResponse.userStatus.code !== 2000) {
        setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
        throw new Error(`${registCheckOutPaymentResponse.userStatus.code}, ${registCheckOutPaymentResponse.userStatus.message}`);
      }
      const { data: checkOutResponse } = await api.checkOut({
        folioNo: bookingItem.folioNo,
        earlyCheckoutYN: 'N',
      });
      if (checkOutResponse.userStatus.code !== 2000) {
        setModalErrorMessage('결제는 성공하였으나, 체크아웃에 실패 하였습니다.');
        throw new Error(`${checkOutResponse.userStatus.code}, ${checkOutResponse.userStatus.message}`);
      }
      history.replace('/checkout/payment/success');
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registCheckOutPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <PaymentLoading
            history={history}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default CheckOutPaymentResponseContainer;