import React, { Fragment, useState, useCallback } from 'react';
import moment from 'moment';
import MobilekeyMenual from './MobilekeyMenual';
import CheckOut from './CheckOut';

const BookingList = ({
  bookingItem,
  checkOut,
  history,
}) => {
  const [ isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual ] = useState(false);
  const [ isOpenModalCheckOut, setIsOpenModalCheckOut ] = useState(false);

  const openModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(true);
  }, []);

  const closeModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(false);
  }, []);

  const openModalCheckout = useCallback(() => {
    setIsOpenModalCheckOut(true);
  }, []);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const zoneTypeFormatter = (roomTypeCode) => {
    if (!roomTypeCode) return '';
    return {
      A: 'A동',
      B: 'B동',
      S: 'SOUTH CONDO',
      N: 'NORTH CONDO',
      C: 'C동',
      D: 'D동',
    }[roomTypeCode.slice(0, 1)] || 'UnKnown';
  };

  const subZoneTypeFormatter = (roomTypeCode, roomNo) => {
    if (!roomTypeCode || !roomNo) return '';
    const zoneType = zoneTypeFormatter(roomTypeCode);
    if (['A동', 'B동'].includes(zoneType)) return ['5', '6', '7'].includes(roomNo.slice(4, 5)) ? '동관' : '서관';
  };

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  const dayFormatter = (day) => {
    return {
      Sun: '일',
      Mon: '월',
      Tue: '화',
      Wed: '수',
      Thu: '목',
      Fri: '금',
      Sat: '토',
    }[day] || day;
  };

  const setBookingPeriod = (date) => {
    return `${moment(date).format('YYYY.MM.DD')} (${moment(date).format('ddd')})`;
  };

  const closeBrowser = () => {
    window.open('','_self').close();

    // window.opener='Self';
    // window.open('','_parent','');
    // window.close();

    // window.open('', '_self', '');
    // window.close();
  };

  return (
    <Fragment>
      <div className='checked-in-background-container'>
        <div className='opacity-background'>
          <span className='welcome-message'>
            Welcome,<br/>
            Guest LEE 
          </span>
          <div className='booking-id-container'>
            <span className='booking-id-label'>
              Reservation Number
            </span>
            <span className='booking-id-value'>
              {bookingItem.rsvnNo}
            </span>
          </div>
        </div>
      </div>
      <div className='checked-in-booking-card-container'>
        <span className='hotel-name'>
          GOLF VILLAGE / {bookingItem.zoneTypeName}
        </span>
        <span className='room-name'>
          {roomNoFormatter(bookingItem.roomNo)}
        </span>
        <div className='horizontal-line mt-15 mb-20'></div>
        <div className='userkey-container'>
          <div className='userkey-item mb-15'>
            <span className='userkey-item-label'>
              Room PIN code
            </span>
            <span className='userkey-item-value'>
              486255*
            </span>
          </div>
          {/* <div className='userkey-item'>
            <span className='userkey-item-label'>
              Mobile Key
              <img className='tip-icon ml-2 mb-4' src='../../assets/images/question-mark.png' alt='' onClick={openModalMobilekeyMenual} />
            </span>
            <span className='userkey-item-value'>
              5a98
            </span>
          </div> */}
        </div>
        <div className='checked-in-booking-period-container'>
          <img className='period-icon mt-2' src='../../assets/images/period.png' alt='' />
          <div className='period-item-container'>
            <div className='period-item mb-8'>
              <span className='period-item-label'>
                Check-In
              </span>
              <span className='period-item-value'>
                {bookingItem.arrvDate ? `${setBookingPeriod(bookingItem.arrvDate)} 15:00` : '-'}
              </span>
            </div>
            <div className='period-item'>
              <span className='period-item-label'>
                Check-Out
              </span>
              <span className='period-item-value'>
                {bookingItem.deptDate ? `${setBookingPeriod(bookingItem.deptDate)} 11:00` : '-'}
              </span>
            </div>
          </div>
        </div>
        {/* <div className='whatsapp-button' onClick={() => closeBrowser()}>
          <img className='whatsapp-logo' src='../../assets/images/whatsapp-logo.png' alt='' />
          <span className='whatsapp-button-text'>Return to WhatsApp</span>
        </div> */}
        {/* <div className='direct-booking-banner-container'>
          <div className='mr-80'>
            <span className='direct-booking-banner-title'>
              다음 여행도<br/>
              시그니엘 서울에서!
            </span>
            <span className='direct-booking-banner-marketing-message'>
              적립된 포인트로 예약해보세요
            </span>
          </div>
          <div className='room-image-container'>
            <img className='room-image' src='../../assets/images/sample-room.jpg' alt='' />
          </div>
        </div> */}
        {/* <div className='check-out-button-container'>
          <span className='check-out-button' onClick={openModalCheckout}>
            모바일 체크아웃 하기
          </span>
        </div> */}
      </div>
      <MobilekeyMenual isOpen={isOpenModalMobilekeyMenual} onClose={closeModalMobilekeyMenual} />
      <CheckOut isOpen={isOpenModalCheckOut} onClose={closeModalCheckOut} checkOut={checkOut} />
    </Fragment>
  );
};

export default BookingList;