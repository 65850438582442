import React, { Fragment } from 'react';
import _ from 'lodash';
import Modal from '../Modal';

const CheckOut = ({
  isOpen,
  onClose,
  checkOut,
}) => {
  const debounceCheckOut = _.debounce(() => checkOut(), 800, { maxWait: 1000 });

  const onClickCheckOut = () => {
    debounceCheckOut();
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <Fragment>
            <span className='check-out-title'>
              체크아웃 하시겠습니까?
            </span>
            <span className='check-out-description'>
              체크아웃 시 해당 객실에 재입장 불가합니다.
            </span>
            <div className='check-out-notice-container'>
              <div className='check-out-notice-title-container'>
                <img className='notice-icon mt-1 mr-4' src='../../assets/images/exclamation-mark.png' alt='' />
                <span className='check-out-notice-title'>
                  추가 서비스 결제
                </span>
              </div>
              <span className='check-out-notice-description'>
                서비스 추가 비용이 있을 경우<br/>
                결제 후 체크아웃이 완료됩니다.
              </span>
            </div>
            <div className='check-out-button-container'>
              <div className='cancel-button' onClick={onClose}>
                <span className='cancel-button-text'>
                  아니요
                </span>
              </div>
              <div className='check-out-button' onClick={onClickCheckOut}>
                <span className='check-out-button-text c-red'>
                  체크아웃 하기
                </span>
              </div>
            </div>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default CheckOut;