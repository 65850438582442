import React, { Fragment } from 'react';

const Payment = ({
  bookingItem,
  userInfo,
  productName,
  totalAmount,
  now,
  encryptedData,
  returnUrl,
  stopUrl,
  history,
}) => {
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  const { smartropay } = window;

  const goPay = () => {
    smartropay.init({
      mode: process.env.REACT_APP_SMARTRO_MODE,		// STG: 테스트, REAL: 운영
    });
    smartropay.payment({
      PayMethod: 'CARD',
      GoodsCnt: '1',
      GoodsName: '오크밸리 비대면 체크인',
      Amt: isTestMode ? 1000 : totalAmount,
      Moid: bookingItem.rsvnNo || 'Moid_20210112145131',    // 상품주문번호
      Mid: process.env.REACT_APP_SMARTRO_MID,
      ReturnUrl: returnUrl,  // Mobile 연동 시 필수
      StopUrl: stopUrl,      // Mobile 연동 시 필수
      BuyerName: '오크밸리',
      BuyerTel: '1588-7676',
      BuyerEmail: 'oakvalley@hdc-resort.com',
      UserIp: '127.0.0.0',
      MallIp: '127.0.0.0',
      EdiDate: now,
      Forward: 'Y',
      EncryptData: encryptedData,
      Callback : function(res) {		// 인증/승인 분리일경우 콜백함수
        var approvalForm = document.approvalForm;
        approvalForm.Tid.value = res.Tid;
        approvalForm.TrAuthKey.value = res.TrAuthKey;
        approvalForm.action = returnUrl;
        approvalForm.submit();
      }
    });
  };

  const totalAmountFormatter = (totalAmount) => {
    return totalAmount ? totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : totalAmount;
  };

  return (
    <Fragment>
      <span className='check-in-payment-title'>
        결제하기
      </span>
      <div className='product-name-container'>
        <span>
          상품명
        </span>
        <span className='product-name-value'>
          {productName}
        </span>
      </div>
      <div className='total-amount-container'>
        <span>
          총 금액
        </span>
        <span className='total-amount-value'>
          {`${totalAmountFormatter(totalAmount)}원`}
        </span>
      </div>
      <button className='check-in-payment-button' onClick={() => goPay()}>결제하기</button>
      <form id="approvalForm" name="approvalForm" method="post">
        <input type="hidden" id="Tid" name="Tid" />
        <input type="hidden" id="TrAuthKey" name="TrAuthKey" />
      </form>
    </Fragment>
  );
};

export default Payment;