import React, { Fragment } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import CheckOutPaymentSuccess from '../components/views/CheckOutPaymentSuccess';

const CheckOutPaymentSuccessContainer = ({
  history,
  match,
}) => {
  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <CheckOutPaymentSuccess
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default CheckOutPaymentSuccessContainer;