import React, { Fragment, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import Modal from '../Modal';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ModalPrivacyPolicy = ({
  isOpen,
  onClose,
}) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal
      isOpen={isOpen}
      isClosable={true}
      onClose={onClose}
      title='약관 내용'
      size='full'
      Content={(
        <Fragment>
          <div>
            <Document
              file='https://s3.ap-northeast-2.amazonaws.com/multifamily.imgate/%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8E%E1%85%A5%E1%84%85%E1%85%B5%E1%84%87%E1%85%A1%E1%86%BC%E1%84%8E%E1%85%B5%E1%86%B7_20211007.pdf'
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode='svg'
            >
              {new Array(numPages).fill([]).map((item, index) => (
                <Page width={300} pageNumber={index + 1} key={index}/>
              ))}
            </Document>
          </div>
        </Fragment>
      )}
    />
  );
};

export default ModalPrivacyPolicy;