import React, { Fragment } from 'react';
import Modal from '../Modal';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MobilekeyMenual = ({
  isOpen,
  onClose,
}) => {
  const moveToApp = () => {
    const appLink = 'https://ovalmms.page.link/key';
    window.open(appLink);
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={true}
        onClose={onClose}
        Content={(
          <Fragment>
            <Slider
              dots={true}
              infinite={false}
              autoplaySpeed={400}
            >
              <div>
                <img className='mobilekey-menual w-60 m-auto mt-15 mb-20' src='../assets/images/app-download.png' alt='' />
                <span className='mobilekey-menual-title'>
                  How to use Mobile Key
                </span>
                <span className='mobilekey-menual-description'>
                  Download Mobile Key App
                </span>
                <div className='app-link-container' onClick={moveToApp}>
                  <img className='download mt-3 mr-6' src='../assets/images/download.png' alt='' />
                  <span className='app-name'>
                    Download
                  </span>
                  <span className='download-label'>
                    OVAL KEY
                  </span>
                </div>
              </div>
              <div>
                <img className='mobilekey-menual mt-15 mb-20' src='../assets/images/mobilekey-input.png' alt='' />
                <span className='mobilekey-menual-title'>
                  How to use Mobile Key
                </span>
                <span className='mobilekey-menual-description h-60 mb-32'>
                  Enter a 4-digits<br/>
                  mobile key code on app.
                </span>
              </div>
              <div>
                <img className='mobilekey-menual mt-15 mb-20' src='../assets/images/doorlock-touch.png' alt='' />
                <span className='mobilekey-menual-title'>
                  How to use Mobile Key
                </span>
                <span className='mobilekey-menual-description h-60'>
                  Touch the door lock on your hands<br/>
                  opening the mobile app.
                </span>
                <span className='mobilekey-setting-description'>
                  Make sure the Location and Bluetooth are on.
                </span>
              </div>
            </Slider>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default MobilekeyMenual;