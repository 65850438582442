import React, { Fragment } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import TermsOfUseList from '../components/views/TermsOfUseList';

const TermsOfUseListContainer = ({
  history,
  match,
}) => {
  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <TermsOfUseList
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default TermsOfUseListContainer;