import React, { Fragment, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import ModalError from '../ModalError';

const ModalLocationSerivce = ({
  isOpen,
  onClose,
  history,
}) => {
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const verifyCurrentLocation = () => {
    return new Promise(function(resolve, reject) { 
      const oakvalleyLat = 37.4139469;
      const oakvalleyLng = 127.8222184;
      const centerRadiusOfOakvalley = 3500; // 오크밸리 리조트 내부 중심으로 부터 반경: 3500m
      const option = {
        enableHighAccuracy: false,
        maximumAge: 5000,
        timeout: 3000,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const currentLat = position.coords.latitude;
          const currentLng = position.coords.longitude;
          const currentDistance = getDistanceFromCurrentLocationToDestination(currentLat, currentLng, oakvalleyLat, oakvalleyLng);
          if (currentDistance - centerRadiusOfOakvalley < 0 || isTestMode) resolve();
          else reject({code: 0, message: '현재 사용자 위치는 오크밸리 리조트 내부가 아닙니다.'});
        }, (error) => {
          const formatErrorMessage = {
            0: '알 수 없는 에러 입니다.',
            1: '위치 서비스가 꺼져 있습니다. 위치 서비스를 켜 주세요.',
            2: '위치를 찾을 수 없습니다.',
            3: '요청 응답 시간이 초과 되었습니다. 재요청 해주세요.'
          };
          reject({code: 0, message: formatErrorMessage[error.code] || error.message});
        }, (option));
      } else reject({code: 0, message: '이 브라우저에서는 Geolocation이 지원되지 않습니다.'});
    });
  };

  function getDistanceFromCurrentLocationToDestination(currentLat, currentLng, destinationLat, destinationLng) {
    var R = 6371; // Radius of the earth in km
    var dLat = toRadians(destinationLat - currentLat);
    var dLng = toRadians(destinationLng - currentLng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(currentLat)) * Math.cos(toRadians(destinationLat)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 1000; // Distance in m
    return d;
  }

  function toRadians(deg) {
    return deg * (Math.PI / 180);
  }

  const confirm = async () => {
    try {
      await verifyCurrentLocation();
      history.push('/terms_of_use');
    } catch (error) {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      onClose();
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <Fragment>
            <span className='location-service-title'>위치 정보 사용을 허용해 주세요</span>
            <div className='location-service-icon'>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <span className='location-service-description'>
              ※모바일 체크인은 오크밸리 리조트 반경 4㎞<br/>
              이내에서 서비스가 활성화 됩니다.
            </span>
            <button className='confirm-button h-40 mb-14' onClick={confirm}>
              확인
            </button>
          </Fragment>
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </Fragment>
  );
};

export default ModalLocationSerivce;