import React, { Fragment } from 'react';
import _ from 'lodash';
import Modal from '../Modal';

const CheckInConfirm = ({
  isOpen,
  selectedRoom,
  onClose,
  checkIn,
}) => {
  const debounceCheckIn = _.debounce(() => checkIn(), 800, { maxWait: 1000 });

  const onClickCheckIn = () => {
    debounceCheckIn();
  };

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  const subZoneTypeNameFormatter = (subZoneTypeCode) => {
    return {
      east: '동관',
      west: '서관',
    }[subZoneTypeCode] || '';
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <Fragment>
            <span className='check-in-confirm-title'>
              Would you like to Check-In?
            </span>
            <span className='check-in-confirm-description'>
              Please check your room information.
            </span>
            <div className='room-info-container'>
              <span className='room-info-description'>
                {`GOLF VILLAGE / ${selectedRoom.zoneTypeName} ${subZoneTypeNameFormatter(selectedRoom.subZoneTypeCode)}`}<br/>
                {`${selectedRoom.floorName} ${roomNoFormatter(selectedRoom.roomNo)}`}
              </span>
            </div>
            <div className='check-in-confirm-button-container'>
              <div className='cancel-button' onClick={onClose}>
                <span className='cancel-button-text'>
                  No
                </span>
              </div>
              <div className='check-in-request-button' onClick={onClickCheckIn}>
                <span className='check-in-request-button-text c-sky-blue'>
                  Check-In
                </span>
              </div>
            </div>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default CheckInConfirm;