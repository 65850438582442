import axios from 'axios';
import moment from 'moment';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_SANHA_BACKEND_BASEURL;
const apiKey = '$2a$12$74i/La/q3cf1ex7tQVKHZ.DPzwADixYluOVOcJMEZ4ukPvdlRKery';
const vendorId = 'HDCRESORT';
const systemId = 'WINGSHDCRESORT';
const langTypeCode = 'KOR';

// config
const request = axios.create({
  baseURL,
});

const headers = (header) => {
  return {
    headers: {
      'API-KEY': apiKey,
      'VENDOR_ID': vendorId,
      'Content-Type': 'application/json',
      ...header && header,
    }
  };
};

const querystring = (query) => {
  return {
    params: query,
  };
};

export const listBooking = ({rsvnNo, rsvnSeqNo}) => {
  return {
    data: {
      echoToken: null,
      versionInfo: {
        version: '1.0.0.0'
      },
      serverStatus: 200,
      userStatus: {
        code: 2000,
        message: 'OK'
      },
      resultData: [
        {
          rsvnNo: '21001088',
          rsvnSeqNo: '1',
          arrvDate: '20230212',
          deptDate: '20230215',
          nights: '1',
          guestName: 'LEE',
          mobileNo: '01012345678',
          natCode: 'KOR',
          roomTypeCode: 'AM31ND',
          roomTypeDesc: 'A콘도미지정31평노블더블베드',
          rsvnStatusCode: 'RR',
          rsvnStatusDesc: 'RR',
          dpsitAmount: null,
          bsnsCode: '11',
          propertyNo: '11',
          propertyName: '골프빌리지'
        }
      ],
    }
  }
};

export const selectCheckedInBooking = ({bsnsCode, propertyNo, folioNo}) => {
  return request.get(`/api/V1/${systemId}/inhouse/${bsnsCode}/${propertyNo}/${folioNo}`, {
    ...headers(),
  });
};

export const confirmCheckInPayment = ({rsvnNo, rsvnSeqNo}) => {
  return request.get(`/api/V1/payments/pg/reservation/${rsvnNo}/${rsvnSeqNo}`, {
    ...headers(),
    ...querystring({
      rsvnNo,
      rsvnSeqNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const confirmCheckInAmount = ({rsvnNo, rsvnSeqNo}) => {
  return request.get(`/api/V1/reservations/${rsvnNo}/${rsvnSeqNo}/amount`, {
    ...headers(),
    ...querystring({
      rsvnNo,
      rsvnSeqNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const registCheckInPayment = ({trnsNo, intApprTypeCode, intPayStatusCode, payFnshDate, successYn, trnsDate, trnsHHMMSS, trnsAmt, cardMonthCnt, cardApprNo, cardName, cardIssueCode, cardPurcCode, trnsBankCode, acctNo, depositor, cashRcptTypeCode, cashRcptApptNo, cashRcptCxlApptNo, responseCode, message1, message2, dpsitPayDivCode, userId, userIp, rsvnNo, rsvnSeqNo, payMgtNo, dpsitPayNo, outYN, outMsg}) => {
  return request.post(`/api/V1/payments/pg/reservation/${rsvnNo}/${rsvnSeqNo}`,
    {
      systemId,
      vendorId,
      trnsNo,                // 거래 승인 번호
      intApprTypeCode,       // 인터넷 결재 유형 코드 (P)
      intPayStatusCode,      // 인터넷 결재 상태 코드 (11)
      payFnshDate,           // 인터넷 지불 일자
      successYn,
      trnsDate,              // 거래일자 (YYYYMMDD 포멧)
      trnsHHMMSS,
      trnsAmt,
      cardMonthCnt,          // 카드 할부 개월 수
      cardApprNo,            // 카드 승인 번호
      cardName,
      cardIssueCode,         // 카드 발급사 코드
      cardPurcCode,          // 카드 매입사 코드
      trnsBankCode,          // 거래 은행 코드
      acctNo,                // 계좌 번호
      depositor,             // 예금주 명
      cashRcptTypeCode,      // 현금 영수증 발급 구분 코드
      cashRcptApptNo,        // 현금 영수증 승인 번호
      cashRcptCxlApptNo,     // 현금 영수증 취소 승인 번호
      responseCode,
      message1,
      message2,
      rsvnNo,
      rsvnSeqNo,
      dpsitPayDivCode,       // 선수금 분류 코드
      userId,
      userIp,
      langTypeCode,
      payMgtNo,
      dpsitPayNo,
      outYN,
      outMsg,
    },
    headers(),
  );
};

export const listAvailableRoom = ({rsvnNo, rsvnSeqNo}) => {
  return {
    data: {
      echoToken: null,
      versionInfo: {
        version: '1.0.0.0'
      },
      serverStatus: 200,
      userStatus: {
        code: 2000,
        message: 'OK'
      },
      resultData: [
        {
          roomNo: '460303',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460304',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460305',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460306',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460309',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460310',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460312',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460315',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460316',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460319',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460321',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460325',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460326',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460327',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460329',
          roomTypeCode: 'SM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460403',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460404',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460406',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460407',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460409',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460412',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460413',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460416',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460418',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460420',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460421',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460423',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460424',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '460427',
          roomTypeCode: 'SM31ND',
          floorCode: '04',
          floorName: '4F',
          zoneTypeCode: 'S',
          zoneTypeName: 'SOUTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430101',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430102',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430103',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430106',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430107',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430109',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430111',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430113',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430114',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430115',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430119',
          roomTypeCode: 'NM31ND',
          floorCode: '01',
          floorName: '1F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430302',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430303',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430305',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430306',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430308',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430309',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430311',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430313',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430314',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430318',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
        {
          roomNo: '430319',
          roomTypeCode: 'NM31ND',
          floorCode: '03',
          floorName: '3F',
          zoneTypeCode: 'N',
          zoneTypeName: 'NORTH CONDO',
          propertyNo: '11',
          propertyName: '골프빌리지'
        },
      ],
      timeStamp: '2021-09-14T04:19:57'
  }  
  }
};

export const checkIn = ({rsvnNo, rsvnSeqNo, roomNo}) => {
  // return request.post('/api/V1/checkins',
  //   {
  //     rsvnNo,
  //     rsvnSeqNo,
  //     systemId,
  //     roomNo,
  //     langTypeCode,
  //   },
  //   headers(),
  // );
  return {
    data: {
      echoToken: null,
      versionInfo: {
        version: '1.0.0.0'
      },
      serverStatus: 200,
      userStatus: {
        code: 2000,
        message: 'OK'
      },
      resultData: [
        {
          folioNo: '21001090',
          roomNo: '410208',
          rsvnNo: '21001047',
          rsvnSeqNo: '1',
          arrvDate: '20210409',
          deptDate: '20210410',
          nights: '1',
          guestName: 'TSTE',
          roomTypeCode: 'BM31ND',
          roomTypeDesc: 'B콘도미지정31평노블더블베드',
          bsnsCode: '11',
          propertyNo: '11',
          propertyName: '골프빌리지',
          roomKeyPincode: 'bf979123b077c69c49ca1ee7c253e0d2c705cb60',
          roomKeyMobileKey: '676747'
        }
      ],
    }
  }
};

export const confirmCheckOutAmount = ({folioNo}) => {
  return request.get(`/api/V1/checkouts/${folioNo}/balance`, {
    ...headers(),
    ...querystring({
      folioNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const registCheckOutPayment = ({trnsNo, intApprTypeCode, intPayStatusCode, payFnshDate, successYn, trnsDate, trnsHHMMSS, trnsAmt, cardMonthCnt, cardApprNo, cardName, cardIssueCode, cardPurcCode, trnsBankCode, acctNo, depositor, cashRcptTypeCode, cashRcptApptNo, cashRcptCxlApptNo, responseCode, message1, message2, folioNo, userId, userIp, dpsitPayDivCode, payMgtNo, dpsitPayNo, outYN, outMsg}) => {
  return request.post(`/api/V1/payment/pg/checkout/${folioNo}`,
    {
      systemId,
      vendorId,
      trnsNo,                // 거래 승인 번호
      intApprTypeCode,       // 인터넷 결재 유형 코드 (P)
      intPayStatusCode,      // 인터넷 결재 상태 코드 (11)
      payFnshDate,           // 인터넷 지불 일자
      successYn,
      trnsDate,              // 거래일자 (YYYYMMDD 포멧)
      trnsHHMMSS,
      trnsAmt,
      cardMonthCnt,          // 카드 할부 개월 수
      cardApprNo,            // 카드 승인 번호
      cardName,
      cardIssueCode,         // 카드 발급사 코드
      cardPurcCode,          // 카드 매입사 코드
      trnsBankCode,          // 거래 은행 코드
      acctNo,                // 계좌 번호
      depositor,             // 예금주 명
      cashRcptTypeCode,      // 현금 영수증 발급 구분 코드
      cashRcptApptNo,        // 현금 영수증 승인 번호
      cashRcptCxlApptNo,     // 현금 영수증 취소 승인 번호
      responseCode,
      message1,
      message2,
      folioNo,
      dpsitPayDivCode,       // 선수금 분류 코드
      userId,
      userIp,
      langTypeCode,
      payMgtNo,
      dpsitPayNo,
      outYN,
      outMsg,
    },
    headers(),
  );
};

export const checkOut = ({folioNo, earlyCheckoutYN}) => {
  return request.post('/api/V1/checkouts',
    {
      folioNo,
      earlyCheckoutYN,
      systemId,
      vendorId,
      langTypeCode,
    },
    headers(),
  );
};