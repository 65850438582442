import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import MainLayout from '../components/layouts/MainLayout';
import InitialBooking from '../components/views/BookingList';
import CheckedInBooking from '../components/views/CheckedInBooking';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import { bookingListAction } from '../stores/actions';
import * as api from '../api/sanha';

const InitialBookingContainer = ({
  history,
  match,
}) => {
  // const { folioNo } = history.location.state ? history.location.state : {};
  const [ isLoading, setIsLoading ] = useState(false);
  const urlQueryStringParams = queryString.parse(history.location.search);
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const { queryStringParams, bookingItem, roomInfo } = useSelector(state => state.bookingList);
  const dispatch = useDispatch();

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const listBooking = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.listBooking(!_.isEmpty(urlQueryStringParams) ? urlQueryStringParams : queryStringParams);
      if (response.userStatus.code !== 2000) {
        setModalErrorMessage('예약 조회에 실패 하였습니다.');
        throw new Error(`${response.userStatus.code}, ${response.userStatus.message}`);
      }
      if (response.resultData && response.resultData.length) {
        dispatch(bookingListAction.setBookingItem(response.resultData[0]));
        // if (response.resultData[0].rsvnStatusCode !== 'RR') await selectCheckedInBooking({
        //   folioNo: response.resultData[0].folioNo,
        //   bsnsCode: response.resultData[0].bsnsCode,
        //   propertyNo: response.resultData[0].propertyNo,
        // });
      }
      if (response.resultData && !response.resultData.length) {
        dispatch(bookingListAction.setBookingItem({}));
        dispatch(bookingListAction.setRoomInfo({}));
      }
    } catch (error) {
      dispatch(bookingListAction.setBookingItem({}));
      dispatch(bookingListAction.setRoomInfo({}));
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryStringParams, urlQueryStringParams]);

  const selectCheckedInBooking = useCallback(async ({folioNo, bsnsCode, propertyNo}) => {
    try {
      const { data: response } = await api.selectCheckedInBooking({bsnsCode, propertyNo, folioNo});
      if (response.userStatus.code !== 2000) {
        setModalErrorMessage('투숙 조회에 실패 하였습니다.');
        throw new Error(`${response.userStatus.code}, ${response.userStatus.message}`);
      }
      if (response.resultData && response.resultData.length) dispatch(bookingListAction.setRoomInfo(response.resultData[0]));
    } catch (error) {
      dispatch(bookingListAction.setRoomInfo({}));
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkOut = async () => {
    try {
      setIsLoading(true);
      const { data: checkOutAmountResponse } = await api.confirmCheckOutAmount({
        folioNo: bookingItem.folioNo || roomInfo.folioNo,
      });
      if (checkOutAmountResponse.userStatus.code !== 2000 && checkOutAmountResponse.userStatus.code !== 3024) {
        setModalErrorMessage('퇴실 잔액 조회에 실패 하였습니다.');
        throw new Error(`${checkOutAmountResponse.userStatus.code}, ${checkOutAmountResponse.userStatus.message}`);
      }
      if (checkOutAmountResponse.resultData.balanceExist === 'N' && checkOutAmountResponse.resultData.balanceAmount) {
        history.push('/checkout/payment', {
          now: moment().format('YYYYMMDDHHmmss'),
          totalAmount: checkOutAmountResponse.resultData.balanceAmount,
        });
      }
      else if (checkOutAmountResponse.resultData.balanceExist === 'Y' && checkOutAmountResponse.resultData.balanceAmount < 0) {
        setModalErrorMessage('체크아웃에 실패 하였습니다.');
        throw new Error('프론트로 퇴실 처리 문의 바랍니다.');
      }
      else {
        const { data: checkOutResponse } = await api.checkOut({
          folioNo: bookingItem.folioNo || roomInfo.folioNo,
          earlyCheckoutYN: 'N',
        });
        if (checkOutResponse.userStatus.code !== 2000) {
          setModalErrorMessage('체크아웃에 실패 하였습니다.');
          if (checkOutResponse.userStatus.code === 3011) throw new Error('퇴실일자를 확인하시기 바랍니다. 체크아웃은 당일만 가능합니다.');
          else throw new Error(`${checkOutResponse.userStatus.code}, ${checkOutResponse.userStatus.message}`);
        }
        history.push('/checkout/payment/success');
        // await listBooking();
      }
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(urlQueryStringParams)) dispatch(bookingListAction.setBookingQueryStringParams(urlQueryStringParams));
    listBooking();
    // if (folioNo && roomNo) selectCheckedInBooking();    // 체크인 api 완료되면 제거
    // else {
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!_.isEmpty(bookingItem) && roomInfo.roomNo ? (    // 체크인, 투숙 조회 api 완료되면 원복
        <MainLayout
          history={history}
          customStyle={{position: 'relative', margin: 0}}
          ContentBody={(
            <CheckedInBooking
              bookingItem={{...bookingItem, ...roomInfo}}
              checkOut={checkOut}
              history={history}
            />
          )}
        />
      ) : (
        <MainLayout
          history={history}
          ContentBody={(
            <InitialBooking
              bookingItem={bookingItem}
              listBooking={listBooking}
              setModalErrorMessage={setModalErrorMessage}
              setModalErrorSubMessage={setModalErrorSubMessage}
              openModalError={openModalError}
              history={history}
            />
          )}
        />
      )}
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default InitialBookingContainer;