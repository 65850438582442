import React, { Fragment } from 'react';


const CheckIn = ({
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
  verificationCode,
  setVerificationCode,
  checkIn,
  history,
}) => {
  // const [ isSendVerificationCode, setIsSendVerificationCode ] = useState(false);
  // const isValidUserInfo = userName && phoneNumber.length === 11;
  // const isValidVerificationCode = verificationCode.length === 4;

  // const changeUserName = (event) => {
  //   setUserName(event.target.value);
  // };

  // const changePhoneNumber = (event) => {
  //   let inputPhoneNumber = event.target.value;
  //   const maxLength = 11;
  //   if (inputPhoneNumber.length > maxLength) inputPhoneNumber = inputPhoneNumber.slice(0, maxLength);
  //   setPhoneNumber(inputPhoneNumber);
  // };

  // const changeVerificationCode = (event) => {
  //   let inputVerificationCode = event.target.value;
  //   const maxLength = 4;
  //   if (inputVerificationCode.length > maxLength) inputVerificationCode = inputVerificationCode.slice(0, maxLength);
  //   setVerificationCode(inputVerificationCode);
  // };

  // const onRequestVerificationCode = () => {
  //   // request verification code api
  //   setIsSendVerificationCode(true);
  // };

  // const onVerifyVerificationCode = () => {
  //   // verify verification code api
  //   history.push('/', {status: 'checked_in'});
  // };

  return (
    <Fragment>
      <span className='verification-title'>
        본인 인증
      </span>
      <span className='verification-description mb-10'>
        카카오 로그인을 통해 본인인증을 하시면<br/>
        비대면 체크인 및 객실키를 발급해 드립니다.
      </span>
      <span className='verification-description'>
        ※ 미성년자(만 19세 미만)의 경우<br/>
        청소년 보호법에 의거하여 보호자의 동의<br/>
        또는 동행 시에만 숙박이 가능합니다.
      </span>
      {/* <span className='verification-item-label'>
        이름
      </span>
      <input className='check-in-input mb-20' placeholder='예약자 성명을 적어주세요' value={userName} onChange={changeUserName} />
      <span className='verification-item-label'>
        휴대폰 번호
      </span>
      <div className='d-flex p-relative'>
        <input className='check-in-input' type='number' placeholder='-없이 입력해 주세요' value={phoneNumber} onChange={changePhoneNumber} />
        <button className='verification-code-send-button' disabled={!isValidUserInfo} onClick={onRequestVerificationCode}>
          인증번호 전송
        </button>
      </div>
      <div className='mt-20' hidden={!isSendVerificationCode}>
        <span className='verification-item-label'>
          인증번호
        </span>
        <div className='d-flex p-relative'>
          <input className='check-in-input mb-8' type='number' placeholder='숫자 4자리를 입력해주세요' value={verificationCode} onChange={changeVerificationCode} />
          <div className='verification-code-resend-button'>
            <span className='verification-code-resend-button-text'>
              재전송
            </span>
          </div>
        </div>
        <div className='d-flex'>
          <img className='notice-icon mr-2' src='../../assets/images/exclamation-mark.png' alt='' />
          <span className='kakao-notice'>
            카카오 메세지로 발송된 인증 번호 4자리를 입력해주세요.
            휴대폰 정보를 올바르게 입력하여도 인증번호가 오지 않으면
            <span className='kakao-notice-link'>여기를 클릭</span>
            하세요.
          </span>
        </div>
      </div>
      <button className='confirm-button mt-30' disabled={!isValidUserInfo || !isValidVerificationCode} onClick={onVerifyVerificationCode}>
        확인
      </button> */}
      <img className='kakao-login-button' src='../../assets/images/kakao-login-button.png' alt='' onClick={checkIn} />
    </Fragment>
  );
};

export default CheckIn;