import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import MainLayout from '../components/layouts/MainLayout';
import PaymentLoading from '../components/views/PaymentLoading';
import ModalError from '../components/ModalError';
import * as api from '../api/sanha';

const CheckInPaymentResponseContainer = ({
  history,
  match,
}) => {
  const urlQueryStringParams = queryString.parse(history.location.search);
  const { bookingItem } = useSelector(state => state.bookingList);
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    history.replace('/');
  };

  const registCheckInPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.ResultCode !== 3001) {
        setModalErrorMessage('결제 요청에 실패 하였습니다.');
        throw new Error('');
      }
      const { data: registCheckInPaymentResponse } = await api.registCheckInPayment({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
        trnsNo: urlQueryStringParams.Tid,
        intApprTypeCode: 'P',
        intPayStatusCode: 11,
        payFnshDate: '',
        successYn: urlQueryStringParams.ResultCode === '3001' ? 'Y' : 'N',
        trnsDate: moment().format('YYYYMMDD'),
        trnsHHMMSS: moment().format('HHmmss'),
        trnsAmt: urlQueryStringParams.Amt,
        cardMonthCnt: urlQueryStringParams.CardQuota || '',
        cardApprNo: urlQueryStringParams.AuthCode || '',
        cardName: urlQueryStringParams.AppCardName || '',
        cardIssueCode: urlQueryStringParams.AppCardCode || '',
        cardPurcCode: urlQueryStringParams.AcquCardCode || '',
        trnsBankCode: '',
        acctNo: '',
        depositor: urlQueryStringParams.BuyerName || '',
        cashRcptTypeCode: 0,
        cashRcptApptNo: '',
        cashRcptCxlApptNo: '',
        responseCode: urlQueryStringParams.ResultCode || '',
        message1: urlQueryStringParams.ResultMsg || '',
        message2: '',
        dpsitPayDivCode: '01',
        userId: 'KEYLESS',
        userIp: '127.0.0.0',
        payMgtNo: '',
        dpsitPayNo: '',
        outYN: '',
        outMsg: '',
      });
      if (registCheckInPaymentResponse.userStatus.code !== 2000) {
        setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
        throw new Error(`${registCheckInPaymentResponse.userStatus.code}, ${registCheckInPaymentResponse.userStatus.message}`);
      }
      history.replace('/room');
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registCheckInPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <PaymentLoading
            history={history}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </Fragment>
  );
};

export default CheckInPaymentResponseContainer;