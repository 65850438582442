import React, { Fragment, useState, useCallback } from 'react';
import _ from 'lodash';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBed, faEye } from '@fortawesome/free-solid-svg-icons';
import CheckInConfirm from './CheckInConfirm';
// import ModalError from '../ModalError';

const RoomSelect = ({
  selectedPropertyList,
  selectedProperty,
  selectedZoneTypeList,
  selectedZoneType,
  selectedSubZoneTypeList,
  selectedSubZoneType,
  selectedFloorList,
  selectedFloor,
  selectedRoomList,
  selectedRoom,
  changeProperty,
  changeZoneType,
  changeSubZoneType,
  changeFloor,
  changeRoom,
  checkIn,
  history,
}) => {
  const [ isOpenModalCheckInConfirm, setIsOpenModalCheckInConfirm ] = useState(false);
  // const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  // const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  // const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalCheckInConfirm = useCallback(() => {
    setIsOpenModalCheckInConfirm(true);
  }, []);

  const closeModalCheckInConfirm = useCallback(() => {
    setIsOpenModalCheckInConfirm(false);
  }, []);

  // const openModalError = useCallback(() => {
  //   setIsOpenModalError(true);
  // }, []);

  // const closeModalError = useCallback(() => {
  //   setIsOpenModalError(false);
  // }, []);

  const condoImageFormatter = (zoneTypeName, subZoneTypeName) => {
    return subZoneTypeName ? {
      'A동': {
        동관: 'a-east-condo',
        서관: 'a-west-condo',
      },
      'B동': {
        동관: 'b-east-condo',
        서관: 'b-west-condo',
      },
    }[zoneTypeName][subZoneTypeName]
    : {
      'SOUTH CONDO': 'south-condo',
      'NORTH CONDO': 'north-condo',
      'C동': 'c-condo',
      'D동': 'd-condo',
    }[zoneTypeName]
  };

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  return (
    <Fragment>
      {selectedRoomList.length ? (
        <Fragment>
          <div className='property-list-container'>
            {selectedPropertyList.map((property, index) => (
              <span
                key={index}
                className={`property-name ${property.propertyName === selectedProperty.propertyName ? 'selected' : property.status || ''}`}
                onClick={() => changeProperty(property)}
              >
                GOLF VILLAGE
              </span>
            ))}
          </div>
          <div className='condo-list-container'>
            {selectedZoneTypeList.map((zoneType, index) => (
              <span
                key={index}
                className={`condo-name ${zoneType.zoneTypeName === selectedZoneType.zoneTypeName ? 'selected' : zoneType.status || ''}`}
                onClick={() => changeZoneType(zoneType)}
              >
                {zoneType.zoneTypeName}
              </span>
            ))}
          </div>
          <div className='p-relative'>
            <img className='condo-map' src={`../../assets/images/${condoImageFormatter(selectedZoneType.zoneTypeName, selectedSubZoneType.subZoneTypeName)}.jpg`} alt='' />
            {/* <img className='compass' src={`../../assets/images/${selectedProperty.propertyNo === '11' ? 'golf-compass' : 'ski-compass'}.png`} alt='' /> */}
          </div>
          <div className='selected-room-info-container'>
            <span className='selected-condo-name'>
              {`GOLF VILLAGE / ${selectedZoneType.zoneTypeName} ${['A동', 'B동'].includes(selectedZoneType.zoneTypeName) ? selectedSubZoneType.subZoneTypeName : ''}`}
            </span>
            <span className='selected-room-name'>
              {`${selectedFloor.floorName} ${roomNoFormatter(selectedRoom.roomNo || '')}`}
            </span>
          </div>
          {['A동', 'B동'].includes(selectedZoneType.zoneTypeName) ? (
            <Fragment>
              <span className='sub-zone-type-list-title'>
                {selectedZoneType.zoneTypeName}
              </span>
              <div className='sub-zone-type-list-container'>
                {selectedSubZoneTypeList.map((subZoneType, index) => (
                  <span
                    key={index}
                    className={`sub-zone-type-name ${subZoneType.subZoneTypeCode === selectedSubZoneType.subZoneTypeCode ? 'selected' : subZoneType.status || ''}`}
                    onClick={() => changeSubZoneType(subZoneType)}
                  >
                    {subZoneType.subZoneTypeName}
                  </span>
                ))}
              </div>
            </Fragment>
          ) : ''}
          <span className='floor-list-title'>
            {['A동', 'B동'].includes(selectedZoneType.zoneTypeName) ? selectedSubZoneType.subZoneTypeName : selectedZoneType.zoneTypeName}
          </span>
          <div className='floor-list-container'>
            {selectedFloorList.map((floor, index) => (
              <span
                key={index}
                className={`floor-name ${floor.floorName === selectedFloor.floorName ? 'selected' :  floor.status || ''} ${floor.subZoneTypeCode && !floor.subZoneTypeCode.includes(selectedSubZoneType.subZoneTypeCode) ? 'hidden' : ''}`}
                onClick={() => changeFloor(floor)}
              >
                {floor.floorName}
              </span>
            ))}
          </div>
          <span className='room-list-title'>
            {selectedFloor.floorName}
          </span>
          <div className='room-list-container'>
            {selectedRoomList.map((room, index) => (
              <Fragment>
                {room.roomNo === 'elevator' && (selectedSubZoneType.subZoneTypeCode === room.subZoneTypeCode) ? (
                  <Fragment>
                    <div></div>
                    <div key={index} className='elevator-name-container'>
                      <span className='elevator-name'>
                        ELEVATOR
                      </span>
                    </div>
                    <div></div>
                  </Fragment>
                ) : (
                  <Fragment>
                    {room.isCenter ? (
                      <Fragment>
                        <div></div>
                        <div
                          key={index}
                          className={`room-item ${room.roomNo === selectedRoom.roomNo ? 'selected' : room.status || ''} ${!room.roomNo ? 'hidden' : ''}`}
                          onClick={() => changeRoom(room)}
                        >
                          <span className='elevator-name'>
                            {roomNoFormatter(room.roomNo)}
                          </span>
                        </div>
                        <div></div>
                      </Fragment>
                    ) : (
                      <div
                        key={index}
                        style={room.customStyle}
                        className={`room-item ${room.roomNo === selectedRoom.roomNo ? 'selected' : room.status || ''} ${!room.roomNo ? 'hidden' : ''}`}
                        hidden={room.subZoneTypeCode && selectedSubZoneType.subZoneTypeCode !== room.subZoneTypeCode}
                        onClick={() => changeRoom(room)}
                      >
                        <span className='room-name'>
                          {roomNoFormatter(room.roomNo)}
                        </span>
                      </div>
                    )}
                  </Fragment>
                )}
                {room.corridorStart && room.corridorSize && (selectedSubZoneType.subZoneTypeCode === room.subZoneTypeCode) ? (
                  <div key={index} className='corridor-name-container' style={{gridArea: `${room.corridorStart} / 2 / span ${room.corridorSize} / span 1`}}>
                    {/* {!room.isHiddenCorridorText ?
                      selectedProperty.propertyNo === '11' ? (
                        <img className={`room-list-golf-compass ${room.view}`} src='../../assets/images/golf-compass.png' alt='' />
                      ) : (
                        <img className={`room-list-ski-compass ${room.view}`} src='../../assets/images/ski-compass.png' alt='' />
                      ) : ''} */}
                  </div>
                ) : ''}
              </Fragment>
            ))}
          </div>
          <div className='ml-30 mr-30 mt-26'>
            <button className='confirm-button' onClick={openModalCheckInConfirm} disabled={_.isEmpty(selectedRoom)}>
              Check-In
            </button>
          </div>
          {/* <div className='property-list-container'>
            {selectedPropertyList.map((propertyName, index) => (
              <span key={index} className={`property-name ${propertyName === selectedPropertyName ? 'selected':''}`} onClick={() => changeProperty(propertyName)}>
                {propertyName}
              </span>
            ))}
          </div>
          <span className='zone-type-list-title'>
            콘도
          </span>
          <div className='zone-type-list-container'>
            {selectedZoneTypeList.map((zoneTypeName, index) => (
              <span key={index} className={`zone-type-name ${zoneTypeName === selectedZoneTypeName ? 'selected':''}`} onClick={() => changeZoneType(zoneTypeName)}>
                {zoneTypeName}
              </span>
            ))}
          </div>
          <span className='floor-list-title'>
            층
          </span>
          <div className='floor-list-container'>
            {selectedFloorList.map((floorName, index) => (
              <span key={index} className={`floor-name ${floorName === selectedFloorName ? 'selected':''}`} onClick={() => changeFloor(floorName)}>
                {floorName}
              </span>
            ))}
          </div>
          <span className='room-list-title'>
            객실
          </span>
          <div className='room-list-container'>
            {selectedRoomList.map((room, index) => (
              <div key={index} className={`room-item ${room.roomNo === selectedRoom.roomNo ? 'selected':''}`} onClick={() => changeRoom(room)}>
                <span className='room-name'>
                  {roomNoFormatter(room.roomNo)}
                </span>
                <span className='room-view'>
                  <FontAwesomeIcon className='mr-3' icon={faEye} />
                  {room.viewName}
                </span>
                <span className='room-bed-type'>
                  <FontAwesomeIcon className='mr-3' icon={faBed} />
                  {room.bedTypeName}
                </span>
              </div>
            ))}
          </div>
          <button className='confirm-button mt-22' onClick={openModalCheckInConfirm}>
            체크인 하기
          </button> */}
        </Fragment>
      ) : (
        <span>선택할 수 있는 객실이 없습니다.</span>
      )}
      {/* <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      /> */}
      <CheckInConfirm
        isOpen={isOpenModalCheckInConfirm}
        selectedRoom={selectedRoom}
        onClose={closeModalCheckInConfirm}
        checkIn={checkIn}
      />
    </Fragment>
  );
};

export default RoomSelect;