import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MainLayout from '../components/layouts/MainLayout';
import Payment from '../components/views/Payment';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import * as api from '../api/sanha';
import * as imgateApi from '../api/imgate';

const CheckInPaymentContainer = ({
  history,
  match,
}) => {
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  // const now = moment().format('YYYYMMDDHHmmss');
  const { bookingItem, userInfo } = useSelector(state => state.bookingList);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ now, setNow ] = useState('');
  const [ totalAmount, setTotalAmount ] = useState('');
  const [ encryptedData, setEncryptedData ] = useState('');
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    // history.replace('/');
  };

  const confirmCheckInPayment = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: confirmCheckInPaymentResponse } = await api.confirmCheckInPayment({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
      });
      if (confirmCheckInPaymentResponse.userStatus.code !== 2000) {
        setModalErrorMessage('예약 결제 여부 확인에 실패 하였습니다.');
        throw new Error(`${confirmCheckInPaymentResponse.userStatus.code}, ${confirmCheckInPaymentResponse.userStatus.message}`);
      }
      if (confirmCheckInPaymentResponse.resultData.payYn === 'Y') history.replace('/room');
      const { data: confirmCheckInAmountResponse } = await api.confirmCheckInAmount({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
      });
      if (confirmCheckInAmountResponse.userStatus.code !== 2000) {
        setModalErrorMessage('예약 결제 금액 확인에 실패 하였습니다.');
        throw new Error(`${confirmCheckInAmountResponse.userStatus.code}, ${confirmCheckInAmountResponse.userStatus.message}`);
      }
      if (!confirmCheckInAmountResponse.resultData.totalAmount) history.replace('/room');
      const currentTime = moment().format('YYYYMMDDHHmmss');
      setNow(currentTime);
      setTotalAmount(confirmCheckInAmountResponse.resultData.totalAmount);
      await encryptData({
        EdiDate: currentTime,
        Amt: isTestMode ? 1000 : confirmCheckInAmountResponse.resultData.totalAmount,
      });
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const encryptData = async ({EdiDate, Amt}) => {
    try {
      const { data: encryptDataResponse } = await imgateApi.encryptData({
        EdiDate,
        Amt,
      });
      setEncryptedData(encryptDataResponse.EncryptData);
    } catch (error) {
      setModalErrorMessage('결제 데이터 생성에 실패 하였습니다.');
      throw new Error(error.response ? `${error.response.data.code}, ${error.response.data.message}` : error.message);
    }
  };

  useEffect(() => {
    confirmCheckInPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <Payment
            bookingItem={bookingItem}
            userInfo={userInfo}
            productName='객실 숙박료'
            totalAmount={totalAmount}
            now={now}
            encryptedData={encryptedData}
            returnUrl={process.env.REACT_APP_SMARTRO_CHECKIN_RETURN_URL}
            stopUrl={process.env.REACT_APP_SMARTRO_CHECKIN_STOP_URL}
            history={history}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default CheckInPaymentContainer;