import './assets/css/index.css';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes/Routes';
import { store, persistor } from './stores';

const { Kakao } = window;
if (!Kakao.isInitialized()) Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
